import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location){
  return originalPush.call(this,location).catch(err => err)
}

const routes = [
  {
    path: '/',
    name: 'main',
    component: () => import('@/views/main.vue'),
    redirect:'/home',
    children:[
      {
        path: '/login',
        name: 'login',
        component: () => import('@/views/login/login.vue'),
      },
      {
        path: '/register',
        name: 'register',
        component: () => import('@/views/login/register.vue'),
      },
      {
        path: '/user',
        name: 'user',
        component: () => import('@/views/user/index.vue'),
      },
      {
        path: '/home',
        name: 'home',
        component: () => import('@/views/home/index.vue')
      },
      {
        path: '/healthy',
        name: 'healthy',
        component: () => import('@/views/healthy/index.vue'),
      },
      {
        path: '/healthy/physiology',
        name: 'physiology',
        component: () => import('@/views/healthy/physiology/index.vue'),
      },
      {
        path: '/healthy/physiology/details',
        name: 'physiologyDetails',
        component: () => import('@/views/healthy/physiology/details.vue'),
      },
      {
        path: '/healthy/music',
        name: 'music',
        component: () => import('@/views/healthy/music/index.vue'),
      },
      {
        path: '/classroom/video',
        name: 'classroom',
        component: () => import('@/views/classroom/video/index.vue'),
      },
      {
        path: '/classroom/video/specialty',
        name: 'classroomVideoSpecialty',
        component: () => import('@/views/classroom/video/specialty.vue'),
      },
      {
        path: '/classroom/video/details',
        name: 'classroomDetails',
        component: () => import('@/views/classroom/video/details.vue'),
      },
      {
        path: '/classroom/film/specialty',
        name: 'classroomFilmSpecialty',
        component: () => import('@/views/classroom/film/specialty.vue'),
      },
      {
        path: '/classroom/film/details',
        name: 'classroomFilmDetails',
        component: () => import('@/views/classroom/film/details.vue'),
      },
      {
        path: '/family/experience',
        name: 'experience',
        component: () => import('@/views/family/experience/index.vue'),
      },
      {
        path: '/family/experience/detail',
        name: 'experienceDetail',
        component: () => import('@/views/family/experience/detail.vue'),
      },
      {
        path: '/family/experience/publish',
        name: 'experiencePublish',
        component: () => import('@/views/family/experience/publish.vue'),
      },
      {
        path: '/family/interaction',
        name: 'interaction',
        component: () => import('@/views/family/interaction/index.vue'),
      },
      {
        path: '/family/interaction/publish',
        name: 'interactionPublish',
        component: () => import('@/views/family/interaction/publish.vue'),
      },
    ]
  }
]

const router = new VueRouter({
  routes,
  // 每次切换路由的时候滚动到页面顶部
  scrollBehavior (to,from,savePosition) {
    return { x: 0, y: 0 }
  }
})

export default router
