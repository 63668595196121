import Vue from 'vue'
import App from './App.vue'
import Vant from 'vant';
import 'vant/lib/index.css';
import router from './router'
import store from './store'
import 'font-awesome/css/font-awesome.min.css'
import service from '@/utils/request'
import '@/utils/rem'
import { previewFileAddress } from '@/utils/file'
import { ImagePreview } from 'vant';
Vue.use(Vant)
Vue.prototype.$imagePreview = (images,startPosition=0)=>{
  ImagePreview({
    images:images,
    startPosition:startPosition,
    closeable: true,
  })
}
Vue.prototype.$axios = service
Vue.prototype.$previewFileAddress = previewFileAddress
Vue.config.productionTip = false

// 创建Vue
new Vue({
  el: '#app',
  render: h => h(App),
  router,
  store,
  beforeCreate() {
      Vue.prototype.$bus = this;
  },
  data: {
    eventHub: new Vue(),
},
}).$mount('#app');

// new Vue({
//   router,
//   store,
//   render: (h) => h(App),
// }).$mount('#app')
